import React from "react";
import PropTypes from "prop-types";

import {
  LIBRARY,
  ResourceContext,
  CurrentUrlContext,
} from "@decathlon/patterns";

const Widget = ({ widget }) => {
  if (!LIBRARY[widget.component]) {
    console.error("Unknown widget", widget.component);
    console.log({ settings: widget.settings });

    return null;
  }

  return React.createElement(LIBRARY[widget.component], { ...widget.settings });
};

const PageRender = ({ page }) => {
  const widgets = page.widgets.filter((w) => Boolean(LIBRARY[w.component]));

  return (
    <ResourceContext.Provider value={page}>
      <CurrentUrlContext.Provider value="">
        <div className="decathlon-patterns">
          {widgets.map((widget, idx) => {
            page.widgets.map((widget, idx) => (
              <Widget key={idx} widget={widget} />
            ));
          })}
        </div>
      </CurrentUrlContext.Provider>
    </ResourceContext.Provider>
  );
};

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
